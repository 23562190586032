exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-alex-js": () => import("./../../../src/pages/artists/Alex.js" /* webpackChunkName: "component---src-pages-artists-alex-js" */),
  "component---src-pages-artists-daria-js": () => import("./../../../src/pages/artists/Daria.js" /* webpackChunkName: "component---src-pages-artists-daria-js" */),
  "component---src-pages-artists-denis-js": () => import("./../../../src/pages/artists/Denis.js" /* webpackChunkName: "component---src-pages-artists-denis-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-artists-rabbit-hole-js": () => import("./../../../src/pages/artists/RabbitHole.js" /* webpackChunkName: "component---src-pages-artists-rabbit-hole-js" */),
  "component---src-pages-artists-rumia-js": () => import("./../../../src/pages/artists/Rumia.js" /* webpackChunkName: "component---src-pages-artists-rumia-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

