module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fluctua Records","short_name":"Fluctua Records","description":"Independent record label and management company from Berlin.","lang":"en","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","crossOrigin":"use-credentials","icon":"src/images/logo-redes-cuadrado.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7b7a27abbe7880de3ed771b494f30a8f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
